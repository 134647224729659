import React, { useState } from "react";

const ProposalRow = ({ proposal }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const statusClasses = proposal.proposalStatus === "Pending" ? "bg-yellow-200 text-yellow-800" : "bg-green-200 text-green-800";

  return (
    <>
      <tr className="border-t border-gray-200 text-base">
        <td className="p-4 pl-8 text-xs">{proposal.proposalId}</td>
        <td className="p-4">
          <div className="font-bold text-black">
            {proposal.fromFirstName} {proposal.fromLastName}
          </div>
          <div className="text-gray-500 text-xs">{proposal.fromEmailAddress}</div>
        </td>
        <td className="p-4">
          <div className="font-bold text-black">
            {proposal.toFirstName} {proposal.toLastName}
          </div>
          <div className="text-gray-500 text-xs">{proposal.toEmailAddress}</div>
        </td>
        <td className="p-4 text-xs">{proposal.scopeName}</td>
        <td className="p-4">
          <span className={`px-3 py-1 rounded-full text-xs font-semibold ${statusClasses}`}>{proposal.proposalStatus}</span>
        </td>
        <td className="p-4 pr-8">
          <button onClick={toggleDropdown} className={`text-blue-500 transform ${isOpen ? "rotate-90" : ""}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </button>
        </td>
      </tr>

      {isOpen && (
        <>
          {/* <div className="px-8">
            <span className="font-bold mb-2">Scope Name</span>
            <div>{proposal.scopeName}</div>
          </div> */}
          <tr className="p-4 border-t-2 border-gray-500 bg-gray-200">
            <td className="p-4 pl-8 align-top">
              <h3 className="font-bold mb-2">Basic Rates</h3>
              <div>Storage Rate: ${proposal.storageRate}</div>
              <div>Handling In Rate: ${proposal.handlingInRate}</div>
            </td>
            <td className="p-4 align-top">
              <h3 className="font-bold mb-2">Handling Rates</h3>
              <div>Handling Out Rate: ${proposal.handlingOutRate}</div>
              <div>Order Processing Rate: ${proposal.orderProcessingRate}</div>
            </td>
            <td className="p-4 align-top">
              <h3 className="font-bold mb-2">Packaging Rates</h3>
              <div>Grade A Pallet Rate: ${proposal.gradeAPalletRate}</div>
              <div>Shrink Wrap Rate: ${proposal.shrinkWrapRate}</div>
            </td>
            <td className="p-4 align-top">
              <h3 className="font-bold mb-2">Labor Rates</h3>
              <div>General Labor Rate: ${proposal.generalLaborRate}</div>
            </td>
            <td className="p-4 align-top">
              <h3 className="font-bold mb-2">Other Rates</h3>
              <div>Banding Per UOM: ${proposal.bandingPerUOM}</div>
              <div>Rush Order Fee: ${proposal.rushOrderFee}</div>
            </td>
            <td className="p-4 pr-8 align-top"></td>
          </tr>
        </>
      )}
    </>
  );
};

export const ProposalList = ({ proposals }) => {
  return (
    <table className="w-full border-collapse text-sm table-fixed relative">
      <thead>
        <tr className="sticky top-0 bg-white z-10">
          <th className="p-4 pl-8 border-b border-gray-200 text-left text-sm uppercase font-bold rounded-xl">Proposal ID</th>
          <th className="p-4 border-b border-gray-200 text-left text-sm uppercase font-bold">Supplier</th>
          <th className="p-4 border-b border-gray-200 text-left text-sm uppercase font-bold">Shipper</th>
          <th className="p-4 border-b border-gray-200 text-left text-sm uppercase font-bold">Scope</th>
          <th className="p-4 border-b border-gray-200 text-left text-sm uppercase font-bold">Status</th>
          <th className="p-4 pr-8 border-b border-gray-200 w-20 rounded-xl" />
        </tr>
      </thead>
      <tbody>
        {proposals.map((proposal) => (
          <ProposalRow key={proposal.proposalId} proposal={proposal} />
        ))}
      </tbody>
    </table>
  );
};
