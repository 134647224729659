import moment from 'moment';

export const dateConvert = (date, format) => {
    if (date) {
        const value = moment(date).format(format);
        return value;
    } else {
        return '';
    }
}

export function arrayGetValueFilters(value, filterItem) {
    const valueFromLocalStorage = localStorage.getItem('filters');
    if (valueFromLocalStorage) {
        const arrayList = JSON.parse(valueFromLocalStorage);
        const newArrayList = arrayList[filterItem];
        //bug fix
        const newArrayfromMapValue = value ? value.split(',') : [];
        const array3 = newArrayList.filter(function (obj) {
            if (newArrayfromMapValue.includes(obj.value.toString())) {
                return obj.label;
            }
        });
        return array3;
    }
}