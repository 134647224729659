import axios from 'axios'
import {baseURL} from '../constants'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.get['Content-Type'] = 'application/json'

const postLogin =  (formObj) => {
    return axios.post(baseURL + 'Account/signin', JSON.stringify(formObj))
}

export default {
    postLogin
}