import ClientsService from '../services/clientServices'
import { UPDATE_WAREHOUSE_DEATILS_FAILED, UPDATE_WAREHOUSE_DEATILS_UPDATE, UPDATE_WAREHOUSE_DEATILS_FAILED_360, UPDATE_WAREHOUSE_DEATILS_UPDATE_360 } from './types'

export const postClientWareHouseUpdate = (formObj) => (dispatch) => {
    const token = 'Bearer ' + localStorage.getItem('token')
    return ClientsService.postClientWareHouseUpdate(formObj, token).then(
        (response) => {
            dispatch({
                type: UPDATE_WAREHOUSE_DEATILS_UPDATE,
                payload: response.data.value
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: UPDATE_WAREHOUSE_DEATILS_FAILED
            });
            return Promise.reject();
        }
    );
};

export const postClientWareHouseUpdate360 = (formObj) => (dispatch) => {
    const token = 'Bearer ' + localStorage.getItem('token')
    return ClientsService.postClientWareHouseUpdate360(formObj, token).then(
        (response) => {
            dispatch({
                type: UPDATE_WAREHOUSE_DEATILS_UPDATE_360,
                payload: response.data.value
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: UPDATE_WAREHOUSE_DEATILS_FAILED_360
            });
            return Promise.reject();
        }
    );
};