import { combineReducers } from 'redux'
import reduxLogin from './reduxLogin'
import reduxGetClients from './reduxGetClients'
import userWareHouse from './userWarehouse';
import userSOW from './userSOW';
import searchList from './searchClients';
import proposals from './proposals';

export default combineReducers({
    reduxLogin,
    reduxGetClients,
    userWareHouse,
    userSOW,
    searchList,
    proposals
})