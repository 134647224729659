import { GET_PROPOSALS_SUCCESS } from '../actions/types'

const initialState = []

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROPOSALS_SUCCESS:
      return {
        ...state,
        proposals: payload,
      };
    default:
      return {
        ...state
      }
  }
}