import ClientsService from '../services/clientServices'
import { GET_WAREHOUSE_SUPPLIER, GET_WAREHOUSE_SUPPLIER_FAILED } from './types'

export const getWareHouseByUserId = (id) => (dispatch) => {
    const token = 'Bearer ' + localStorage.getItem('token')
    return ClientsService.getWareHouseByIdAPI(id, token).then(
        (response) => {
            dispatch({
                type: GET_WAREHOUSE_SUPPLIER,
                payload: { ...response.data.value.warehouses }
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: GET_WAREHOUSE_SUPPLIER_FAILED
            });
            return Promise.reject();
        }
    );
};