export const POST_LOGIN = "POST_LOGIN";
export const POST_LOGIN_FAILED = "POST_LOGIN_FAILED";

export const GET_CLIENTS_SHIPPERS = "GET_CLIENTS_SHIPPERS";
export const GET_CLIENTS_SHIPPERS_FAILED = "GET_CLIENTS_SHIPPERS_FAILED";

export const GET_CLIENTS_SUPPLIERS = "GET_CLIENTS_SUPPLIERS";
export const GET_CLIENTS_SUPPLIERS_FAILED = "GET_CLIENTS_SUPPLIERS_FAILED";

export const POST_CLIENT_UPDATE = "POST_CLIENT_UPDATE";
export const POST_CLIENT_UPDATE_FAILED = "POST_CLIENT_UPDATE_FAILED";

export const LOGOUT = 'LOGOUT';

export const GET_WAREHOUSE_SUPPLIER = 'GET_WAREHOUSE_SUPPLIER';
export const GET_WAREHOUSE_SUPPLIER_FAILED = 'GET_WAREHOUSE_SUPPLIER_FAILED';

export const GET_SOW_PER_SHIPPER = 'GET_SOW_PER_SHIPPER';
export const GET_SOW_PER_SHIPPER_FAILED = 'GET_SOW_PER_SHIPPER_FAILED';


export const POST_CLIENT_SOW_UPDATE = "POST_CLIENT_SOW_UPDATE";
export const POST_CLIENT_SOW_UPDATE_FAILED = "POST_CLIENT_SOW_UPDATE_FAILED";

export const GET_SEARCH_USER_REQUEST = 'GET_SEARCH_USER_REQUEST';
export const GET_SEARCH_USER_SUCCESS = 'GET_SEARCH_USER_SUCCESS';
export const GET_SEARCH_USER_FAILED = 'GET_SEARCH_USER_FAILED';

export const UPDATE_WAREHOUSE_DEATILS_UPDATE = 'UPDATE_WAREHOUSE_DEATILS_UPDATE';
export const UPDATE_WAREHOUSE_DEATILS_FAILED = 'UPDATE_WAREHOUSE_DEATILS_FAILED';

export const UPDATE_WAREHOUSE_DEATILS_UPDATE_360 = 'UPDATE_WAREHOUSE_DEATILS_UPDATE_360';
export const UPDATE_WAREHOUSE_DEATILS_FAILED_360 = 'UPDATE_WAREHOUSE_DEATILS_FAILED_360';

export const GET_PROPOSALS = 'GET_PROPOSALS';
export const GET_PROPOSALS_SUCCESS = 'GET_PROPOSALS_SUCCESS';
export const GET_PROPOSALS_FAILED = 'GET_PROPOSALS_FAILED';