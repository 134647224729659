import axios from "axios";
import { baseURL } from "../constants";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";

const getUsers = (formObj, token) => {
  return axios.post(baseURL + "Admin/GetUsers", JSON.stringify(formObj), {
    headers: { Authorization: token },
  });
};

//We're restructuring the API to update based on the ID and not email. WIP FEB27/2021
const postClientUpdate = (formObj, token) => {
  return axios.post(baseURL + "Admin/UpdateUserDetails", formObj, {
    headers: { Authorization: token },
  });
};

/**
 * Fetching the warehouse by userId
 * 
 */
const getWareHouseByIdAPI = (id, token) => {
  return axios.get(`${baseURL}Admin/GetUserWarehouse?id=${id}`, {
    headers: {
      Authorization: token
    }
  })
}
/**
 * Fetching dropdown values
 * 
 */

export const getDropdownDataAPI = () => {
  const token = 'Bearer ' + localStorage.getItem('token')
  return new Promise((resolve, reject) => {
    axios.get(`https://api.popcapacity.com/Dropdown/getall`, {
      headers: {
        Authorization: token
      }
    }).then((data) => {
      resolve(data);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * 
 * FETCH USER SOW PER ID
 * 
 */
export const fetchSOWuserById = (id, token) => {
  return new Promise((resolve, reject) => {
    axios.get(`${baseURL}Admin/GetScopeByShipper?shipperId=${id}`, {
      headers: {
        Authorization: token
      }
    }).then((data) => {
      resolve(data);
    }).catch(err => {
      reject(err);
    })
  })
}

/***
 *
 * UPDATING SOW data
 */

const postClientSOWUpdate = (formObj, id, token) => {
  return axios.put(`${baseURL}Admin/EditScope?id=${id}`, formObj, {
    headers: { Authorization: token },
  });
};
/**
 * 
 * SEARCH USER API
 * 
 */
const fetchSearchUser = (emailString, token) => {
  return new Promise((resolve, reject) => {
    axios.get(`${baseURL}Admin/SearchUser?emailString=${emailString}`, {
      headers: {
        Authorization: token
      }
    }).then((data) => {
      resolve(data);
    }).catch(err => {
      reject(err);
    })
  })
}
/**
 * 
 * UPADATING THE USER WAREHOUSE 
 * @param {formData} // its a formdata
 * @param {token} 
 * 
 */
const postClientWareHouseUpdate = (fromData, token) => {
  return new Promise((resolve, reject) => {
    axios.post(`${baseURL}Admin/UpdateWarehouseDetails`, fromData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: token
      }
    }).then((data) => {
      resolve(data);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * 
 * UPADATING THE USER WAREHOUSE 360 images
 * @param {formData} // its a formdata
 * @param {token} 
 * 
 */
const postClientWareHouseUpdate360 = (fromData, token) => {
  return new Promise((resolve, reject) => {
    axios.post(`${baseURL}Admin/Upload360Images`, fromData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: token
      }
    }).then((data) => {
      resolve(data);
    }).catch(err => {
      reject(err);
    })
  })
}
const updateUserPassword = (obj) => {
  const token = 'Bearer ' + localStorage.getItem('token');
  return new Promise((resolve, reject) => {
    axios.post(`${baseURL}Admin/ChangeUserPassword`, JSON.stringify(obj), {
      headers: {
        Authorization: token
      }
    }).then((data) => {
      resolve(data);
    }).catch(err => {
      reject(err);
    })
  })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUsers,
  postClientUpdate,
  getWareHouseByIdAPI,
  fetchSOWuserById,
  postClientSOWUpdate,
  fetchSearchUser,
  postClientWareHouseUpdate,
  postClientWareHouseUpdate360,
  updateUserPassword
};
