import {Fragment} from 'react';
// import Loader from '../../../common/loader';
import {useSelector} from 'react-redux';
import WarehouseDetails from './warehouse';
import SowInterface from './sowInterface';
export default function Warehouses({role, mainId}) {
	const warehouseList = useSelector(
		(state) => state.userWareHouse.wareHouseListing
	);
	const sowList = useSelector((state) => state.userSOW.sowListing);
	function isEmpty(obj) {
		for (var prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				return false;
			}
		}
		return JSON.stringify(obj) === JSON.stringify({});
	}
	function renderData(role) {
		if (role === 'supplier') {
			return isEmpty(warehouseList)
				? 'No warehouses Found'
				: warehouseList &&
						Object.values(warehouseList) &&
						Object.values(warehouseList).length > 0 &&
						Object.values(warehouseList).map((data, i) => {
							return (
								<Fragment key={i}>
									<WarehouseDetails {...data} mainId={mainId} />
								</Fragment>
							);
						});
		} else {
			return isEmpty(sowList)
				? 'No SOW Found for this user'
				: sowList &&
						Object.values(sowList) &&
						Object.values(sowList).length > 0 &&
						Object.values(sowList).map((data, i) => {
							return (
								<Fragment key={i}>
									<SowInterface {...data} />
								</Fragment>
							);
						});
		}
	}
	return (
		<ul className="relative" x-max={1}>
			{/* <Loader /> */}
			{renderData(role)}
		</ul>
	);
}
