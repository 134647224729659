import {Fragment, useState, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';

export default function ThreeUpload(props) {
	const {handleMultipleFileUplodThree} = props;
	const onDrop = useCallback((acceptedFiles) => {
		// Do something with the files
		console.log(
			'>>>> Dropped files have been 360 v accepted <<<<',
			acceptedFiles
		);
		handleMultipleFileUplodThree(acceptedFiles);
	}, []);

	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
	return (
		<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
			<div className="mt-1 sm:mt-0 sm:col-span-3">
				<div
					onClick={(e) => e.stopPropagation()}
					{...getRootProps({className: '360images'})}
					className={` ${
						isDragActive ? `border-gray-400 bg-gray-300` : `border-gray-300`
					} w-full flex justify-center px-6 pt-5 pb-6 border-2  border-dashed rounded-md`}
				>
					<div className="space-y-1 text-center">
						<svg
							className="mx-auto h-12 w-12 text-gray-400"
							stroke="currentColor"
							fill="none"
							viewBox="0 0 48 48"
							aria-hidden="true"
						>
							<path
								d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<div
							className="flex text-sm text-gray-600"
							onClick={(e) => e.stopPropagation()}
						>
							<label
								// htmlFor="file-upload"
								className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
							>
								<span>Upload 360 Photos</span>
								<input
									// id="file-upload"
									// type="file"
									// accept="image/*"
									// multiple={true}
									// className="sr-only"
									// name="file-upload"
									// onChange={(event) =>
									// 	handleMultipleFileUplodThree(event)
									// }
									{...getInputProps()}
								/>
							</label>
							<p className="pl-1">drag and drop here</p>
						</div>
						<p className="text-xs text-gray-500">
							PNG, JPG, GIF up to 10MB per Image
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
