import { GET_SOW_PER_SHIPPER, GET_SOW_PER_SHIPPER_FAILED } from './types';
import ClientsService from '../services/clientServices'

export const getSOWPerUserByID = (id) => (dispatch) => {
    const token = 'Bearer ' + localStorage.getItem('token')
    return ClientsService.fetchSOWuserById(id, token).then(
        (response) => {
            dispatch({
                type: GET_SOW_PER_SHIPPER,
                payload: { ...response.data.value }
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: GET_SOW_PER_SHIPPER_FAILED
            });
            return Promise.reject();
        }
    );
};