import { GET_PROPOSALS, GET_PROPOSALS_SUCCESS, GET_PROPOSALS_FAILED } from './types'
import ProposalsService from '../services/proposals';


export const getProposals = () => (dispatch) => {
  const token = 'Bearer ' + localStorage.getItem('token');
  return new Promise((resolve, reject) => {
    dispatch({
      type: GET_PROPOSALS
    })
    ProposalsService.fetchProposals(token).then((resp) => {
      dispatch({
        type: GET_PROPOSALS_SUCCESS,
        payload: { ...resp.data.proposals }
      })
      resolve(200);
    }).catch(err => {
      console.log(err);
      dispatch({
        type: GET_PROPOSALS_FAILED
      })
      reject(400);
    })
  })
};
