import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import useOnclickOutside from "react-cool-onclickoutside";
import { logoutAction } from "../../actions";
import Crown from "../../assets/anim/crown.json";
import popLogo from "../../assets/popSVG_color.svg";
import { GetTokenDetails } from "../../helpers/checkLoginStatus";

const logoAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: Crown,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const Navigation = ({
  children,
  isLogoAnimationPaused,
  isLogoAnimationStopped,
  link,
  linkText
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [profileDrop, setProfileDrop] = useState(false);
  const [email, setEmail] = useState('false');

  const ref = useOnclickOutside(() => {
    setProfileDrop(false);
  });

  function profileToggle() {
    setProfileDrop(!profileDrop);
  }

  const handleLogout = () => {
    dispatch(logoutAction(history));
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userData = GetTokenDetails(token);
    setEmail((userData && userData.sub) || '');
  }, [])

  return (
    <nav className="flex-none bg-indigo-900 z-20">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex items-center px-2 lg:px-0 xl:w-64">
            <div className="flex-shrink-0">
              <img className="h-6 w-auto" src={popLogo} alt="Workflow" />
              <Lottie
                style={{ position: 'absolute', top: 2, left: 130 }}
                options={logoAnimationOptions}
                height={25}
                width={40}
                isStopped={isLogoAnimationStopped}
                isPaused={isLogoAnimationPaused}
              />
            </div>
          </div>

          {children}

          <div className="flex lg:hidden">
            <button
              className="bg-indigo-900 inline-flex items-center justify-center p-2 rounded-md text-indigo-400 hover:text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="hidden lg:block lg:w-80">
            <div className="flex items-center justify-end">
              <div className="flex">
                {link && linkText && (
                  <Link to={link}	className="px-3 py-2 rounded-md text-sm font-medium text-indigo-200 hover:text-white">
                    {linkText}
                  </Link>
                )}
              </div>

              <div className="ml-4 relative flex-shrink-0">
                <div>
                  <button
                    className="bg-indigo-700 flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white"
                    id="user-menu"
                    aria-haspopup="true"
                    onClick={profileToggle}
                  >
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full"
                      src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixqx=F33MSaPokq&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&h=256&q=80"
                      alt={'image'}
                    />
                  </button>
                </div>
                <div
                  ref={ref}
                  className={
                    'origin-top-right absolute z-10 right-0 mt-2 w-52 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 ' +
                    (profileDrop
                      ? 'visible transform opacity-100 scale-100'
                      : 'hidden transform opacity-0 scale-95')
                  }
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <div className="px-4 py-3">
                    <p className="text-sm">Signed in as</p>
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {email || ''}
                    </p>
                  </div>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    View Profile
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Settings
                  </a>
                  <a
                    onClick={handleLogout}
                    className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 cursor-pointer"
                    role="menuitem"
                  >
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:hidden">
        <div className="px-2 pt-2 pb-3">
          <a
            href="#"
            className="block px-3 py-2 rounded-md text-base font-medium text-white bg-indigo-800"
          >
            Dashboard
          </a>
          <a
            href="#"
            className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600"
          >
            Support
          </a>
        </div>
        <div className="pt-4 pb-3 border-t border-indigo-800">
          <div className="px-2">
            <a
              href="#"
              className="block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600"
            >
              Your Profile
            </a>
            <a
              href="#"
              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600"
            >
              Settings
            </a>
            <a
              href="#"
              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600"
            >
              Sign out
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};