import React, { useState, useEffect, useMemo } from 'react';
import Paginator from '../../common/paginator';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { logoutAction } from '../../../actions/postLogin';
import { getProposals } from "../../../actions";
import { ProposalList } from "./components/ProposalList";
import { Navigation } from "../../common/Navigation";

const LIMIT_PROPOSALS_PER_PAGE = 20;

export default function Proposals() {
  const dispatch = useDispatch();

  const proposals = useSelector((state) => state.proposals?.proposals);

  const [selectedPage, setSelectedPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');

  const searchFilteredProposals = useMemo(() => {
    const filtered = Object
      .values(proposals ?? {})
      .filter((proposal) => proposal?.scopeName?.toLowerCase()?.includes(search.toLowerCase()));
    setTotal(filtered.length);

    return filtered;
  }, [search, proposals]);

  const pageFilteredProposals = useMemo(() => {
    const startIndex = selectedPage * LIMIT_PROPOSALS_PER_PAGE;
    const endIndex = (selectedPage + 1) * LIMIT_PROPOSALS_PER_PAGE;

    return searchFilteredProposals.filter(
      (_, index) => index >= startIndex && index < endIndex);
  }, [searchFilteredProposals, selectedPage]);

  const onPageChange = (pageNumber) => {
    setSelectedPage(pageNumber.selected);
  };

  useEffect(() => {
    setSelectedPage(0);
  }, [total]);

  useEffect(() => {
    dispatch(getProposals());
  }, []);

  return (
    <>
      <div
        className="fixed top-0 left-0 w-full h-full bg-gray-50"
        aria-hidden="true"
      />
      <div className="absolute w-full h-full">
        <div className="flex flex-1 flex-col h-full w-full">

          <Navigation link="/dashboard" linkText="Dashboard">
            <div className="flex-1 flex justify-center lg:justify-end">
              <div className="w-full px-2 lg:px-6">
                <label htmlFor="search" className="sr-only">
                  Search by Scope Name
                </label>
                <div className="relative text-indigo-200 focus-within:text-gray-400">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">

                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-indigo-400 bg-opacity-25 text-indigo-100 placeholder-indigo-200 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
                    placeholder="Search by Scope Name"
                    type="text"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </div>
              </div>
            </div>
          </Navigation>

          <div className="flex flex-1 w-full max-w-7xl mx-auto xl:px-8 rounded-xl overflow-y-auto">
            <div className="flex flex-col flex-1 my-4 border bg-white rounded-xl overflow-hidden">
              <div className="flex-1 bg-white rounded-xl overflow-y-auto table-scrollbar">
                <ProposalList proposals={pageFilteredProposals} />
              </div>

              <Paginator
                onPageChange={onPageChange}
                selectedPage={selectedPage}
                totalCount={total}
                limit={LIMIT_PROPOSALS_PER_PAGE}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
