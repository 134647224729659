import { GET_WAREHOUSE_SUPPLIER, GET_CLIENTS_SHIPPERS_FAILED, UPDATE_WAREHOUSE_DEATILS_FAILED, UPDATE_WAREHOUSE_DEATILS_UPDATE, UPDATE_WAREHOUSE_DEATILS_FAILED_360, UPDATE_WAREHOUSE_DEATILS_UPDATE_360 } from '../actions/types'

const initialState = {
    wareHouseListing: [],
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_WAREHOUSE_SUPPLIER:
            return {
                ...state,
                wareHouseListing: payload
            };
        case GET_CLIENTS_SHIPPERS_FAILED:
            return {
                ...state,
                wareHouseListing: []
            };
        case UPDATE_WAREHOUSE_DEATILS_FAILED:
            return {
                ...state
            };
        case UPDATE_WAREHOUSE_DEATILS_UPDATE:
            return {
                ...state
            };
        case UPDATE_WAREHOUSE_DEATILS_FAILED_360:
            return {
                ...state
            };
        case UPDATE_WAREHOUSE_DEATILS_UPDATE_360:
            return {
                ...state
            };
        default:
            return {
                ...state
            }
    }
}