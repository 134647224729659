import { GET_SEARCH_USER_SUCCESS, GET_SEARCH_USER_REQUEST, GET_SEARCH_USER_FAILED } from './types'
import ClientsService from '../services/clientServices';


export const getSearchClients = (params) => (dispatch) => {
    const token = 'Bearer ' + localStorage.getItem('token');
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_SEARCH_USER_REQUEST
        })
        ClientsService.fetchSearchUser(params, token).then((resp) => {
            console.log(resp);
            dispatch({
                type: GET_SEARCH_USER_SUCCESS,
                payload: { ...resp.data.value.userResponses }
            })
            resolve(200);
        }).catch(err => {
            console.log(err);
            dispatch({
                type: GET_SEARCH_USER_FAILED
            })
            reject(400);
        })
    })
};

export const clearSearch = () => dispatch => {
    dispatch({
        type: GET_SEARCH_USER_SUCCESS,
        payload: null
    })
}
