import {Fragment, useState, useEffect} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Warehouses from '../screens/dashboard/components/warehouseSettings';
import {useDispatch} from 'react-redux';
import {postClientUpdate} from '../../actions/modifyClients';
import {getWareHouseByUserId, getSOWPerUserByID} from '../../actions';
import service from '../../services/clientServices';

export default function HTInputs({contactDetails, role, updateListFunc}) {
	const [selectedTab, setSelectedTab] = useState(0);

	const [userDetails, setUserDetails] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const handleChange = (e) => {
		const {name, value} = e.target;
		if (name === 'block') {
			const newVal = parseInt(value);
			setUserDetails((prevState) => ({
				...prevState,
				[name]: newVal === 1 ? true : false,
			}));
		} else if (name === 'active') {
			const newVal = parseInt(value);
			setUserDetails((prevState) => ({
				...prevState,
				[name]: newVal === 1 ? true : false,
			}));
		} else {
			setUserDetails((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	useEffect(() => {
		setUserDetails(contactDetails);
		if (role === 'supplier' && contactDetails && contactDetails.id) {
			dispatch(getWareHouseByUserId(contactDetails.id));
		} else if (role === 'shipper' && contactDetails && contactDetails.id) {
			dispatch(getSOWPerUserByID(contactDetails.id));
		}
	}, [contactDetails]);

	function updateAccountByType(kind) {
		switch (kind) {
			case 0:
				let newObject = {...userDetails};
				newObject.Name = userDetails.firstName;
				newObject.block = userDetails.block;
				newObject.active = userDetails.active;
				dispatch(postClientUpdate(newObject))
					.then((res) => {
						updateListFunc(role, 20);
					})
					.catch((err) => {
						console.log('err');
					});
				return console.log('User Details>>');
			case 1:
				return console.log('Warehouse Update');
			case 2:
				return console.log('Photos');
			case 3:
				return console.log('Billing');
			case 4:
				let resObj = {...userDetails};
				const obj = {
					userId: contactDetails.id,
					password: resObj.password,
				};
				if (resObj.password === resObj.newPassword) {
					setIsLoading(true);
					service
						.updateUserPassword(obj)
						.then((res) => {
							setIsLoading(false);
							console.log('updated');
						})
						.catch((err) => {
							setIsLoading(false);
							console.log('err');
						});
				} else {
					alert('Your password is not matched');
				}
				return console.log('reset password!');
			default:
				return console.log('Please Select a Tab Above');
		}
	}

	function updateWarehouses() {}

	function updatePhotos() {}

	function getBillingDetails() {}

	return (
		<Fragment>
			<Tabs
				selectedIndex={parseInt(selectedTab)}
				onSelect={(index) => setSelectedTab(index)}
				className="flex flex-col flex-1 cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
				selectedTabClassName={'flex-1 border-indigo-500 text-indigo-600'}
				selectedTabPanelClassName="overflow-y-auto py-8 flex-1"
			>
				<TabList className="flex flex-none">
					<div className="w-full">
						<div className="sm:hidden">
							<label htmlFor="tabs" className="sr-only">
								Select a tab
							</label>
							<select
								defaultValue={0}
								onChange={(e) => setSelectedTab(e.target.value)}
								id="tabs"
								name="tabs"
								className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
							>
								<option value={0}>Contact Details</option>
								<option value={1}>
									{role === 'shipper' ? 'Scope' : 'Warehouses'}
								</option>
								<option value={2}>Photos</option>
								<option value={3}>Billing</option>
								<option value={4}>Reset Password</option>
							</select>
						</div>
						<div className="hidden sm:block">
							<div className="border-b border-gray-200">
								<nav className="-mb-px flex" aria-label="Tabs">
									<Tab className="w-1/4 py-4 px-4 text-center border-b-2 font-medium text-sm">
										Contact Details
									</Tab>
									<Tab className="w-1/4 py-4 px-4 text-center border-b-2 font-medium text-sm">
										{role === 'shipper' ? 'Scope' : 'Warehouses'}
									</Tab>
									{/* Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
									<Tab
										className="w-1/4 py-4 px-4 text-center border-b-2 font-medium text-sm"
										aria-current="page"
									>
										Activity
									</Tab>
									<Tab className="w-1/4 py-4 px-4 text-center border-b-2 font-medium text-sm">
										Billing
									</Tab>
									<Tab className="w-1/4 py-4 px-4 text-center border-b-2 font-medium text-sm">
										Reset Password
									</Tab>
								</nav>
							</div>
						</div>
					</div>
				</TabList>
				<TabPanel>
					<div className="col-span-6 sm:col-span-3">
						<label
							htmlFor="first_name"
							className="block text-sm font-medium text-gray-700"
						>
							First name
						</label>
						<input
							onChange={handleChange}
							type="text"
							value={(userDetails && userDetails.firstName) || ''}
							name="firstName"
							id="firstName"
							autoComplete="given-name"
							className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
					<div className="mt-4 col-span-6 sm:col-span-3">
						<label
							htmlFor="last_name"
							className="block text-sm font-medium text-gray-700"
						>
							Last name
						</label>
						<input
							type="text"
							onChange={handleChange}
							value={(userDetails && userDetails.lastName) || ''}
							name="lastName"
							id="last_name"
							autoComplete="family-name"
							className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
					<div className="mt-4 col-span-6 sm:col-span-4">
						<label
							htmlFor="email_address"
							className="block text-sm font-medium text-gray-700"
						>
							Email address
						</label>
						<input
							type="text"
							onChange={handleChange}
							value={(userDetails && userDetails.email) || ''}
							name="email"
							id="email_address"
							autoComplete="email"
							className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
					<div className="mt-4 col-span-6 sm:col-span-4">
						<label
							htmlFor="phone_number"
							className="block text-sm font-medium text-gray-700"
						>
							Phone Number
						</label>
						<input
							id="phone"
							type="tel"
							onChange={handleChange}
							value={(userDetails && userDetails.phoneNumber) || ''}
							name="phoneNumber"
							autoComplete="email"
							className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
					<div className="my-4 col-span-6 sm:col-span-4">
						<label
							htmlFor="email_address"
							className="block text-sm font-medium text-red-700"
						>
							Email Verification (Danger Zone)
						</label>
						<select
							id="country"
							onChange={handleChange}
							value={
								(userDetails &&
								userDetails.active &&
								userDetails.active === true
									? '1'
									: '0') || ''
							}
							name="active"
							className="mt-1 shadow-sm focus:ring-indigo-500 focus:border-red-500 block w-full sm:text-sm border-red-300 rounded-md"
						>
							<option value={'1'}>Verified</option>
							<option value={'0'}>Not Verified</option>
						</select>
					</div>
					<div className="my-4 col-span-6 sm:col-span-4">
						<label
							htmlFor="email_address"
							className="block text-sm font-medium text-red-700"
						>
							User Moderation State (Danger Zone)
						</label>
						<select
							id="country"
							onChange={handleChange}
							value={
								(userDetails && userDetails.block && userDetails.block === true
									? 1
									: 0) || ''
							}
							name="block"
							className="mt-1 shadow-sm focus:ring-indigo-500 focus:border-red-500 block w-full sm:text-sm border-red-300 rounded-md"
						>
							<option value={0}>Active</option>
							<option value={1}>Blocked</option>
						</select>
					</div>
				</TabPanel>
				<TabPanel>
					<Warehouses
						role={role}
						mainId={contactDetails && contactDetails.id}
					/>
				</TabPanel>
				<TabPanel>3</TabPanel>
				<TabPanel>4</TabPanel>
				<TabPanel>
					<div className="col-span-6 sm:col-span-3">
						<label
							htmlFor="first_name"
							className="block text-sm font-medium text-gray-700"
						>
							New Password
						</label>
						<input
							onChange={handleChange}
							type="text"
							value={(userDetails && userDetails.password) || ''}
							name="password"
							id="password"
							autoComplete="given-name"
							className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
					<div className="col-span-6 sm:col-span-3">
						<label
							htmlFor="first_name"
							className="block text-sm font-medium text-gray-700"
						>
							Confirm new Password
						</label>
						<input
							onChange={handleChange}
							type="text"
							value={(userDetails && userDetails.newPassword) || ''}
							name="newPassword"
							id="newPassword"
							autoComplete="given-name"
							className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
				</TabPanel>
				{parseInt(selectedTab) === 0 ? (
					<button
						type="button"
						onClick={() => updateAccountByType(selectedTab)}
						className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 w-52"
					>
						{/* Heroicon name: solid/mail */}
						<svg
							className="-ml-1 mr-3 h-5 w-5"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
							/>
						</svg>
						Update Details
					</button>
				) : parseInt(selectedTab) === 4 ? (
					<button
						type="button"
						onClick={() => updateAccountByType(selectedTab)}
						className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 w-52"
					>
						{/* Heroicon name: solid/mail */}
						<svg
							className="-ml-1 mr-3 h-5 w-5"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
							/>
						</svg>

						{isLoading ? 'Updating' : 'Update Password'}
					</button>
				) : null}
			</Tabs>
		</Fragment>
	);
}
