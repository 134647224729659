import ClientsService from '../services/clientServices'
import { GET_CLIENTS_SUPPLIERS, GET_CLIENTS_SUPPLIERS_FAILED, GET_CLIENTS_SHIPPERS, GET_CLIENTS_SHIPPERS_FAILED } from './types'

export const getClients = (formObj) => (dispatch) => {
    const token = 'Bearer ' + localStorage.getItem('token')
    return ClientsService.getUsers(formObj, token).then(
        (response) => {
            dispatch({
                type: formObj.role === 'supplier' ? GET_CLIENTS_SUPPLIERS : GET_CLIENTS_SHIPPERS,
                payload: { ...response.data.value }
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: formObj.role === 'supplier' ? GET_CLIENTS_SUPPLIERS_FAILED : GET_CLIENTS_SHIPPERS_FAILED
            });
            return Promise.reject();
        }
    );
};