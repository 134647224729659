import { GET_SOW_PER_SHIPPER, GET_SOW_PER_SHIPPER_FAILED, POST_CLIENT_SOW_UPDATE, POST_CLIENT_SOW_UPDATE_FAILED } from '../actions/types'

const initialState = {
    sowListing: []
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SOW_PER_SHIPPER:
            return {
                ...state,
                sowListing: payload
            };
        case GET_SOW_PER_SHIPPER_FAILED:
            return {
                ...state,
                sowListing: []
            };
        case POST_CLIENT_SOW_UPDATE:
            return {
                ...state,
                updateSow: payload
            };
        case POST_CLIENT_SOW_UPDATE_FAILED:
            return {
                ...state,
            };
        default:
            return {
                ...state
            }
    }
} 