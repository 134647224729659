import React, {useState, useEffect} from 'react';
import MultiSelect from 'react-multi-select-component';
import {arrayGetValueFilters} from '../../../../helpers/custom';
import {useDispatch} from 'react-redux';
import {postClientSOWUpdate} from '../../../../actions/updateSOW';
import Spinner from '../../../common/spinner';

const SowInterface = (props) => {
	const dispatch = useDispatch();
	const {
		id,
		scopeName,
		location,
		state,
		city,
		approxRequiredSqaureFootage,
		projectTerm,
		facilityCertifications,
		useCase,
		averageInboundQty,
		averageInboundVolume,
		averageOutboundQty,
		averageOutboundVolume,
	} = props;
	const valueFromLocalStorage = localStorage.getItem('filters');
	const jsonList = JSON.parse(valueFromLocalStorage);
	const [sowDetails, setSowDetails] = useState(props);
	const [facilityList, setFacilityList] = useState(
		arrayGetValueFilters(facilityCertifications, 'facilityCertifications')
	);
	const [isFacility, setIsFacility] = useState(false);
	const [isSquareFoot, setIsSquareFoot] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleDropdownChangeFacility = (e) => {
		setFacilityList(e);
	};
	const handleChange = (e) => {
		e.preventDefault();
		const {name, value} = e.target;
		setSowDetails((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};
	useEffect(() => {
		setSowDetails(props);
	}, [props && props.id]);

	const onSubmitSow = (e) => {
		e.preventDefault();
		const newObject = {...sowDetails};
		const values =
			facilityList && facilityList.length
				? facilityList.map((val, i) => {
						return val.value.toString();
				  })
				: '';
		const labels =
			facilityList && facilityList.length
				? facilityList.map((val, i) => {
						return val.label;
				  })
				: '';
		if (
			facilityList &&
			facilityList.length &&
			newObject.approxRequiredSqaureFootage
		) {
			setIsFacility(false);
			setIsSquareFoot(false);
			newObject.FacilityCertifications = values.join();
			newObject.FacilityCertificationsValues = labels.join();
			const newObject1 = {
				ScopeName: newObject.scopeName,
				Location: newObject.location,
				City: newObject.city,
				State: newObject.state,
				longitude: newObject.longitude,
				latitude: newObject.latitude,
				FacilityCertifications: values.join(),
				UseCase: parseInt(newObject.useCase),
				ProjectedStartDate: newObject.projectedStartDate,
				ProjectTerm: parseInt(newObject.projectTerm),
				ProjectTermType: newObject.projectTermType,
				ProjectTermDurationType: newObject.projectTermDurationType,
				Commodity: newObject.commodity,
				ProductUoM: newObject.productUoM,
				ProductDimensions: newObject.productDimensions,
				ProductDimensionsType: newObject.productDimensionsType,
				ProductWeight: newObject.productWeight,
				ProductWeightType: newObject.productWeightType,
				Quantity: newObject.quantity,
				QuantityType: newObject.quantityType,
				Stackable: newObject.stackable,
				StackableType: newObject.StackableType,
				Rackable: newObject.rackable,
				RackableType: newObject.rackableType,
				ApproxRequiredSqaureFootage: parseInt(
					newObject.approxRequiredSqaureFootage
						? newObject.approxRequiredSqaureFootage
						: 0
				),
				AverageInboundQty: parseInt(
					newObject.averageInboundQty ? newObject.averageInboundQty : 0
				),
				AverageInboundQtyType: newObject.averageInboundQtyType,
				AverageInboundVolume: parseInt(
					newObject.averageInboundVolume ? newObject.averageInboundVolume : 0
				),
				AverageInboundVolumeType: newObject.averageInboundVolumeType,
				AverageOutboundQty: parseInt(
					newObject.averageOutboundQty ? newObject.averageOutboundQty : 0
				),
				AverageOutboundQtyType: newObject.averageOutboundQtyType,
				AverageOutboundVolume: newObject.averageOutboundVolume,
				AverageOutboundVolumeType: newObject.averageOutboundVolumeType,
			};
			setIsLoading(true);
			dispatch(postClientSOWUpdate(newObject1, newObject.id))
				.then((resp) => {
					setIsLoading(false);
				})
				.catch((err) => {
					setIsLoading(false);
				});
		} else if (facilityList && facilityList.length === 0) {
			setIsFacility(true);
		} else if (newObject.approxRequiredSqaureFootage === '') {
			setIsSquareFoot(true);
		}
	};
	return (
		<div className="m-2 relative bg-white border-lg shadow rounded-md px-6 py-4">
			<div className="px-4 py-5 sm:px-6">
				<h2
					id="applicant-information-title"
					className="text-lg leading-6 font-medium text-gray-900"
				>
					{scopeName || ''}
				</h2>
				<p className="mt-1 max-w-2xl text-sm text-gray-500">
					{location + ', ' + city + ', ' + state}
				</p>
			</div>
			<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
				<div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
					<div className="sm:col-span-2 col-span-2">
						<dt className="text-sm font-medium text-gray-500">
							Facility Certifications Required
						</dt>
						<MultiSelect
							className="bg-gray-200 mt-2 focus:ring-gray-200 focus:border-gray-300 block w-full sm:text-sm border-white rounded-md"
							options={jsonList.facilityCertifications}
							value={facilityList}
							onChange={handleDropdownChangeFacility}
							labelledBy={'Facility Certifications'}
							overrideStrings={{
								selectSomeItems: 'Facility Certifications',
							}}
							disableSearch={true}
							hasSelectAll={false}
						/>
						{isFacility ? (
							<span className="text-sm font-medium text-red-500">
								This is required field
							</span>
						) : null}
					</div>
					<div className="sm:col-span-1 col-span-2">
						<dt className="text-sm font-medium text-gray-500">
							Required Square Footage
						</dt>
						<input
							type="text"
							name="approxRequiredSqaureFootage"
							id="email"
							className="bg-gray-200 mt-2 focus:ring-gray-200 focus:border-gray-300 block w-full sm:text-sm border-white rounded-md"
							placeholder={'Required Square Footage'}
							value={sowDetails.approxRequiredSqaureFootage || ''}
							onChange={handleChange}
						/>
						{isSquareFoot ? (
							<span className="text-sm font-medium text-red-500">
								This is required field
							</span>
						) : null}
					</div>
					<div className="sm:col-span-1">
						<dt className="text-sm font-medium text-gray-500">Project Term</dt>
						<input
							type="text"
							name="projectTerm"
							id="projectTerm"
							className="bg-gray-200 mt-2 focus:ring-gray-200 focus:border-gray-300 block w-full sm:text-sm border-white rounded-md"
							placeholder={'Project Term'}
							value={sowDetails.projectTerm || ''}
							onChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-1">
						<dt className="text-sm font-medium text-gray-500">
							Average Inbound Qty
						</dt>
						<input
							type="text"
							name="averageInboundQty"
							id="averageInboundQty"
							className="bg-gray-200 mt-2 focus:ring-gray-200 focus:border-gray-300 block w-full sm:text-sm border-white rounded-md"
							value={sowDetails.averageInboundQty || ''}
							onChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-1">
						<dt className="text-sm font-medium text-gray-500">
							Average Inbound Vol
						</dt>
						<input
							type="text"
							name="averageInboundVolume"
							id="email"
							className="bg-gray-200 mt-2 focus:ring-gray-200 focus:border-gray-300 block w-full sm:text-sm border-white rounded-md"
							value={
								sowDetails.averageInboundVolume
									? sowDetails.averageInboundVolume.toString()
									: ''
							}
							onChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-1">
						<dt className="text-sm font-medium text-gray-500">
							Average Out-Bound Qty
						</dt>
						<input
							type="text"
							name="averageOutboundQty"
							id="AverageOutboundQty"
							className="bg-gray-200 mt-2 focus:ring-gray-200 focus:border-gray-300 block w-full sm:text-sm border-white rounded-md"
							placeholder={'Average Out-Bound Qty'}
							value={sowDetails.averageOutboundQty}
							onChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-1">
						<dt className="text-sm font-medium text-gray-500">
							Average Out-Bound Vol
						</dt>
						<input
							type="text"
							name="useCase"
							id="useCase"
							className="bg-gray-200 mt-2 focus:ring-gray-200 focus:border-gray-300 block w-full sm:text-sm border-white rounded-md"
							value={sowDetails.useCase}
							onChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2 col-span-2">
						<dt className="text-sm font-medium text-gray-500">
							Industries Served
						</dt>
						<input
							type="text"
							name="commodity"
							id="commodity"
							className="bg-gray-200 mt-2 focus:ring-gray-200 focus:border-gray-300 block w-full sm:text-sm border-white rounded-md"
							placeholder={'industries served'}
							value={sowDetails.commodity}
							onChange={handleChange}
						/>
					</div>
				</div>
			</div>
			<div className="pt-5 border-t border-gray-200">
				<div className="flex justify-end">
					<button
						type="button"
						className="bg-white py-2 px-4 border border-red-500 rounded-md shadow-sm text-sm font-medium text-red-500 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
					>
						Delete
					</button>
					<button
						type="button"
						onClick={onSubmitSow}
						className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-32"
					>
						{isLoading ? <Spinner /> : 'Update Scope'}
					</button>
				</div>
			</div>
		</div>
	);
};

export default SowInterface;
