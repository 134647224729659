import React, {useState, useEffect, Fragment} from 'react';
import popLogo from '../../../assets/popSVG_color.svg';
import MList from '../../common/mList';
import Paginator from '../../common/paginator';

import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';

import Lottie from 'react-lottie';
import Crown from '../../../assets/anim/crown.json';

import useOnclickOutside from 'react-cool-onclickoutside';

import {logoutAction} from '../../../actions/postLogin';
import {getClients} from '../../../actions/populateClients';
import {getSearchClients, clearSearch} from '../../../actions/searchClients';

import {GetTokenDetails} from '../../../helpers/checkLoginStatus';
import HoverThrough from '../../common/hoverThrough';
import HTInputs from '../../common/hoverThough_inputs';
import {getDropdownDataAPI} from '../../../services/clientServices';
import StatBar from '../../common/statBar';

export default function Dashboard() {
	const dispatch = useDispatch();
	const history = useHistory();

	const [isPaused, setIsPaused] = useState(false);
	const [isStopped, setIsStopped] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [profileDrop, setProfileDrop] = useState(false);

	const userListData = useSelector((state) => state.reduxGetClients.Suppliers);
	const userShippers = useSelector((state) => state.reduxGetClients.Shippers);
	const {searchList, isLoader} = useSelector((state) => state.searchList);
	const [userList, setUserList] = useState([]);
	const [email, setEmail] = useState('');
	const [roleOption, setRoleOption] = useState('supplier');

	const [isPanelShowing, setIsPanelShowing] = useState(false);

	// const [userDetails, setUserDetails] = useState();
	const [query, setQuery] = useState('');

	const [contactDetails, setContactDetails] = useState();
	const [selectedPage, setSelectedPage] = useState(1);
	const [toLimit, setToLimit] = useState(20);
	const [fromLimit, setFromLimit] = useState(0);
	const [total, setTotal] = useState(0);

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: Crown,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getSearchClientrs(query);
		}, 500);
		return () => clearTimeout(timeOutId);
	}, [query]);

	const getSearchClientrs = (data) => {
		if (data !== '') {
			dispatch(getSearchClients(data));
		} else {
			console.log('clearing processs');
			dispatch(clearSearch());
			populateClients(roleOption, 0, 20);
		}
	};

	function handleCloseModal() {
		if (isPanelShowing) {
			setIsPanelShowing(false);
		} else {
			setIsPanelShowing(true);
		}
	}

	function showClientDetails(user) {
		handleCloseModal();
		setContactDetails(user);
	}

	useEffect(() => {
		populateClients('supplier', fromLimit, toLimit);
		const token = localStorage.getItem('token');
		const userData = GetTokenDetails(token);
		setEmail((userData && userData.sub) || '');
		loadgetAllDetails();
	}, []);

	useEffect(() => {
		if (query === '') {
			if (roleOption === 'supplier') {
				setUserList(userListData && userListData.userResponses);
				setTotal(userListData && userListData.totalCount);
			} else {
				setUserList(userShippers && userShippers.userResponses);
				setTotal(userShippers && userShippers.totalCount);
			}
		} else {
			setUserList(searchList);
		}
	}, [userListData && userListData.userResponses, userShippers, searchList]);

	/**
	 *
	 * loading all required values
	 *
	 */
	function loadgetAllDetails() {
		const value = localStorage.getItem('filters');
		if (value === null) {
			getDropdownDataAPI()
				.then((resp) => {
					localStorage.setItem('filters', JSON.stringify(resp.data.value));
				})
				.catch((err) => {
					console.log('err', JSON.stringify(err));
				});
		}
	}

	function populateClients(type, from, to) {
		const populateClient_object = {
			role: type,
			from: from,
			to: to,
		};
		dispatch(getClients(populateClient_object));
	}

	const ref = useOnclickOutside(() => {
		setProfileDrop(false);
	});

	function profileToggle(e) {
		if (!profileDrop) {
			setProfileDrop(true);
		} else {
			setProfileDrop(false);
		}
	}

	const handleLogout = () => {
		dispatch(logoutAction(history));
	};

	const onChangeSelect = (e) => {
		e.preventDefault();
		const value = e.target.value;
		setRoleOption(value);
		populateClients(value, fromLimit, toLimit);
	};
	const nextOffset = (currentPage) => {
		if (currentPage !== 0) {
			const next_offset = (currentPage + 2 - 1) * 20;
			return next_offset;
		} else {
			return 20;
		}
	};
	const previousOffset = (currentPage) => {
		if (currentPage !== 0) {
			return (currentPage + 1 - 1) * 20;
		} else {
			return 0;
		}
	};

	const onPageChange = (pageNumber) => {
		setSelectedPage(pageNumber.selected);
		const page = pageNumber.selected;
		populateClients(roleOption, previousOffset(page), nextOffset(page));
	};

	return (
		<div>
			<div
				className="fixed top-0 left-0 w-1/2 h-full bg-white"
				aria-hidden="true"
			/>
			<div
				className="fixed top-0 right-0 w-1/2 h-full bg-gray-50"
				aria-hidden="true"
			/>
			<div className="absolute w-full h-full">
				<div className="flex flex-1 flex-col h-full w-full">
				{/* Navbar */}
				<nav className="flex-none bg-indigo-900">
					<div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
						<div className="relative flex items-center justify-between h-16">
							{/* Logo section */}
							<div className="flex items-center px-2 lg:px-0 xl:w-64">
								<div className="flex-shrink-0">
									<img className="h-6 w-auto" src={popLogo} alt="Workflow" />
									<Lottie
										style={{position: 'absolute', top: 2, left: 130}}
										options={defaultOptions}
										height={25}
										width={40}
										isStopped={isStopped}
										isPaused={isPaused}
									/>
								</div>
							</div>
							{/* Search section */}
							<div className="flex-1 flex justify-center lg:justify-end">
								<div className="w-full px-2 lg:px-6">
									<label htmlFor="search" className="sr-only">
										Search clients
									</label>
									<div className="relative text-indigo-200 focus-within:text-gray-400">
										<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
											{/* Heroicon name: solid/search */}
											<svg
												className="h-5 w-5"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												aria-hidden="true"
											>
												<path
													fillRule="evenodd"
													d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
													clipRule="evenodd"
												/>
											</svg>
										</div>
										<input
											id="search"
											name="search"
											className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-indigo-400 bg-opacity-25 text-indigo-100 placeholder-indigo-200 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
											placeholder="Search clients"
											type="search"
											onChange={(event) => setQuery(event.target.value)}
										/>
									</div>
								</div>
							</div>
							<div className="flex lg:hidden">
								{/* Mobile menu button */}
								<button
									className="bg-indigo-900 inline-flex items-center justify-center p-2 rounded-md text-indigo-400 hover:text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
									aria-expanded="false"
								>
									<span className="sr-only">Open main menu</span>
									{/* Icon when menu is closed. */}
									{/* Heroicon name: outline/menu-alt-1 Menu open: "hidden", Menu closed: "block"*/}
									<svg
										className="block h-6 w-6"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M4 6h16M4 12h8m-8 6h16"
										/>
									</svg>
									<svg
										className="hidden h-6 w-6"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M6 18L18 6M6 6l12 12"
										/>
									</svg>
								</button>
							</div>
							{/* Links section */}
							<div className="hidden lg:block lg:w-80">
								<div className="flex items-center justify-end">
									<div className="flex">
										<Link to="/proposals"	className="px-3 py-2 rounded-md text-sm font-medium text-indigo-200 hover:text-white">
											Proposals
										</Link>
									</div>
									{/* Profile dropdown */}
									<div className="ml-4 relative flex-shrink-0">
										<div>
											<button
												className="bg-indigo-700 flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white"
												id="user-menu"
												aria-haspopup="true"
												onClick={profileToggle}
											>
												<span className="sr-only">Open user menu</span>
												<img
													className="h-8 w-8 rounded-full"
													src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixqx=F33MSaPokq&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&h=256&q=80"
													alt={'image'}
												/>
											</button>
										</div>
										<div
											ref={ref}
											className={
												'origin-top-right absolute z-10 right-0 mt-2 w-52 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 ' +
												(profileDrop
													? 'visible transform opacity-100 scale-100'
													: 'hidden transform opacity-0 scale-95')
											}
											role="menu"
											aria-orientation="vertical"
											aria-labelledby="user-menu"
										>
											<div className="px-4 py-3">
												<p className="text-sm">Signed in as</p>
												<p className="text-sm font-medium text-gray-900 truncate">
													{email || ''}
												</p>
											</div>
											<a
												href="#"
												className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
												role="menuitem"
											>
												View Profile
											</a>
											<a
												href="#"
												className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
												role="menuitem"
											>
												Settings
											</a>
											<a
												onClick={handleLogout}
												className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
												role="menuitem"
											>
												Logout
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="hidden lg:hidden">
						<div className="px-2 pt-2 pb-3">
							<a
								href="#"
								className="block px-3 py-2 rounded-md text-base font-medium text-white bg-indigo-800"
							>
								Dashboard
							</a>
							<a
								href="#"
								className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600"
							>
								Support
							</a>
						</div>
						<div className="pt-4 pb-3 border-t border-indigo-800">
							<div className="px-2">
								<a
									href="#"
									className="block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600"
								>
									Your Profile
								</a>
								<a
									href="#"
									className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600"
								>
									Settings
								</a>
								<a
									href="#"
									className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600"
								>
									Sign out
								</a>
							</div>
						</div>
					</div>
				</nav>
				{/* 3 column wrapper */}
				<div className="flex flex-1 overflow-y-auto w-full max-w-7xl mx-auto xl:px-8 lg:flex">
					{/* Left sidebar & main wrapper */}
					<div className="flex-1 min-w-0 bg-white xl:flex">
						<div className="border-b border-gray-200 xl:border-b-0 xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200 bg-white">
							<div className="h-full pl-4 pr-6 py-6 sm:pl-6 lg:pl-8 xl:pl-0">
								{/* Start left column area */}
								<div className="h-full relative" style={{minHeight: '12rem'}}>
									<div className="bg-white overflow-hidden shadow rounded-lg">
										<div className="px-4 py-5 sm:p-6">
											<div>
												<h3 className="text-lg leading-6 font-medium text-gray-900">
													Filter Search
												</h3>
												<div className="grid grid-cols-6 gap-6">
													<div className="col-span-6 sm:col-span-6">
														<div className="sm:col-span-3 mt-6">
															<div className="mt-1">
																<select
																	id="country"
																	name="country"
																	autoComplete="country"
																	className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
																	onChange={onChangeSelect}
																>
																	<option value="supplier">Warehouses</option>
																	<option value="shipper">Shippers</option>
																</select>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="bg-white lg:min-w-0 lg:flex-1">
							<div className="h-full">
								{/* Start main area*/}
								<div className="flex h-full flex-1 flex-col">
									{/* <StatBar/> */}
									<div className="flex-1 overflow-y-auto bg-white">
										<ul className="flex-1 overflow-y-auto divide-y divide-gray-200">
											{userList &&
												Object.values(userList).length > 0 &&
												Object.values(userList).map((user, i) => {
													return (
														<Fragment key={i}>
															<MList
																userTypeName={user.name}
																address={user.city}
																email={user.email}
																active={user.active}
																closeDate={user.registrationDate}
																phoneNumber={user.contactNumber}
																onClick={() => showClientDetails(user)}
																block={user.block}
																warehouseModels={user.warehouseModels}
															/>
														</Fragment>
													);
												})}
										</ul>
									</div>
									<Paginator
										onPageChange={onPageChange}
										selectedPage={selectedPage}
										totalCount={total}
										limit={20}
									/>
								</div>
								{/* End main area */}
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
			<HoverThrough
				title={'Account Overview'}
				isShowing={isPanelShowing}
				onCloseClick={handleCloseModal}
			>
				<HTInputs
					contactDetails={contactDetails}
					role={roleOption}
					updateListFunc={populateClients}
				/>
			</HoverThrough>
		</div>
	);
}
