import AuthService from '../services/authentication'
import { POST_LOGIN, POST_LOGIN_FAILED, LOGOUT } from './types'

export const postLogin = (formObj) => (dispatch) => {
    return AuthService.postLogin(formObj).then(
        (response) => {
            dispatch({
                type: POST_LOGIN,
                payload: { ...response }
            });
            localStorage.setItem('token', response.data.value.access_token);
            return Promise.resolve();
        },
        (error) => {
            const message = error.responsedispatch({
                type: POST_LOGIN_FAILED
            });
            return Promise.reject();
        }
    );
};

export const logoutAction = (history) => (dispatch) => {
    localStorage.clear();
    dispatch({
        type: LOGOUT
    });
    history.push('/');
}