import { GET_SEARCH_USER_SUCCESS, GET_SEARCH_USER_REQUEST, GET_SEARCH_USER_FAILED } from '../actions/types';

const initialState = {
    isLoader: false,
    searchList: null
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SEARCH_USER_REQUEST:
            return {
                ...state,
                searchList: null,
                isLoader: true
            };
        case GET_SEARCH_USER_SUCCESS:
            return {
                ...state,
                searchList: payload,
                isLoader: false
            };
        case GET_SEARCH_USER_FAILED:
            return {
                ...state,
                searchList: null,
                isLoader: false
            };
        default:
            return {
                ...state
            }
    }
}