import React, { useEffect } from 'react';

import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';

import Dashboard from '../components/screens/dashboard'
import Proposals from '../components/screens/proposals'
import Login from '../components/screens/login'
import { checkLoginStatus } from '../helpers/checkLoginStatus'

export default function Routes() {
    function PrivateRoute({ component,
        path }) {
        const isLoggedIn = checkLoginStatus();
        return (
            isLoggedIn ? <Route exact path={path} component={component} /> : <Redirect to={"/"} />
        )
    }
    return (
        <BrowserRouter>
            <Switch>
                <Route path={"/"} exact component={Login} />
                <PrivateRoute path={'/dashboard'} component={Dashboard} />
                <PrivateRoute path={'/proposals'} component={Proposals} />
                <Redirect to={"/"} />
            </Switch>
        </BrowserRouter>
    )
}