import {Fragment, useState, useCallback} from 'react';
import {arrayGetValueFilters} from '../../../../helpers/custom';
import MultiSelect from 'react-multi-select-component';
import ImagePreview from '../../../common/imagePreview';
import Image3DPreview from '../../../common/image3DPreview';
import {useDispatch} from 'react-redux';
import {
	postClientWareHouseUpdate,
	postClientWareHouseUpdate360,
} from '../../../../actions/updateWareHouse';
import Spinner from '../../../common/spinner';
import {getWareHouseByUserId} from '../../../../actions/populateWarehouse';
import {useDropzone} from 'react-dropzone';
import ThreeUpload from './threeupload';

export default function WarehouseDetails(props) {
	var {
		id,
		warehouseName,
		location,
		locationAddress,
		totalSqaureFootage,
		availableSqaureFootage,
		storageInfastructure,
		facilityCertifications,
		images,
		photospheres,
		industriesServed,
	} = props;
	const dispatch = useDispatch();
	const valueFromLocalStorage = localStorage.getItem('filters');
	const jsonList = JSON.parse(valueFromLocalStorage);
	const [storage, setStorage] = useState(
		arrayGetValueFilters(storageInfastructure, 'storageInfrastructures')
	);
	const [facilityList, setFacilityList] = useState(
		arrayGetValueFilters(facilityCertifications, 'facilityCertifications')
	);
	const [wareHouseImage, setWareHouseImage] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const convertLoopImageObjetArr = (images) => {
		if (images && images.length > 0) {
			const imageObj = [];
			images.forEach((ele, index) => {
				imageObj.push({
					id: ele.id,
					imageURL: ele.imageURL,
					isDeleted: false,
					tempId: index + 1,
					image: null,
				});
			});
			return imageObj;
		}
	};
	const convertThreeDImages = (threeD) => {
		let photoSpheres = [
			// {
			// 	id: null,
			// photoSpheresName: '',
			// photoSpheresImage: null,
			// imageType: ImageType.Panorama,
			// isDeleted: false,
			// },
		];
		if (threeD && threeD.length > 0) {
			photoSpheres = [];
			threeD.forEach((ele, index) => {
				photoSpheres.push({
					id: ele.id,
					photoSpheresName: ele.viewName,
					photoSpheresImage: ele.viewURL,
					imageType: ele.imageType,
					isDeleted: false,
					tempId: index + 1,
				});
			});
			return photoSpheres;
		}
	};
	const [warehouseImages, setWarehouseImages] = useState(
		images ? convertLoopImageObjetArr(images) : []
	);
	const [photoSpheres, setPhotoSpheres] = useState(
		photospheres ? convertThreeDImages(photospheres) : []
	);
	const [warehouseImagesUpdate, setWarehouseImagesUpdate] = useState(
		warehouseImages
	);

	const [wareHouse3DImage, setWareHouse3DImage] = useState(null);
	const [modal3DOpen, setModal3DOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleDropdownChange = (e) => {
		setStorage(e);
	};
	const handleDropdownChangeFacility = (e) => {
		setFacilityList(e);
	};
	const openImageLink = (e, image) => {
		e.preventDefault();
		setModalOpen(true);
		setWareHouseImage(image);
	};
	const disableLink = (e) => {
		e.preventDefault();
		setModalOpen(false);
		setWareHouseImage(null);
	};

	const openImage3DLink = (e, image) => {
		e.preventDefault();
		setModal3DOpen(true);
		setWareHouse3DImage(image);
	};
	const disable3DLink = (e) => {
		e.preventDefault();
		setModal3DOpen(false);
		setWareHouse3DImage(null);
	};

	const onDrop = useCallback((acceptedFiles) => {
		// Do something with the files
		console.log('>>>> Dropped files have been accepted <<<<', acceptedFiles);
		handleMultipleFileUplod(acceptedFiles);
	}, []);

	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

	const handleMultipleFileUplod = (dropfiles) => {
		// e.preventDefault();
		let index = 0;
		let allFiles = [];
		let isNotImage = false;
		const imagesData = warehouseImages ? warehouseImages : [];
		let lastIndex = 0;
		if (imagesData && imagesData.length > 0) {
			lastIndex = imagesData[imagesData.length - 1].tempId;
		}
		for (let file of dropfiles) {
			if (file.type.includes('image/')) {
				allFiles.push(file);
			} else {
				isNotImage = true;
			}
		}
		// if (isNotImage && allFiles.length == 0) {
		// 	e.target.value = null;
		// 	alert('Only image file is allowed.');
		// 	// setTimeout(() => {
		// 	// 	this.props.clearMessage();
		// 	// }, 0);
		// } else {
		// 	e.target.value = null;
		// 	if (isNotImage) {
		// 		alert('Only image file is allowed.');
		// 		// setTimeout(() => {
		// 		// 	this.props.clearMessage();
		// 		// }, 0);
		// 	}
		var uploadFile = () => {
			if (index < allFiles.length) {
				let file = allFiles[index];
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					imagesData.push({
						id: null,
						imageURL: reader.result,
						isDeleted: false,
						tempId: lastIndex + 1,
						image: file,
					});
					lastIndex = lastIndex + 1;
					index++;
					uploadFile();
				};
				reader.onerror = (error) => {
					index++;
					uploadFile();
				};
			} else {
				setWarehouseImages([...imagesData]);
				setWarehouseImagesUpdate([...imagesData]);
			}
		};
		uploadFile();
		// }
	};
	const handleMultipleFileUplodThree = (drop360) => {
		// e.preventDefault();
		// let allFiles = [];
		// let isNotImage = false;
		const imagesData = photoSpheres ? photoSpheres : [];
		let lastIndex = 0;

		let index = 0;
		let allFiles = [];
		let isNotImage = false;

		if (imagesData && imagesData.length > 0) {
			lastIndex = imagesData[imagesData.length - 1].tempId;
		}
		const formData = new FormData();
		for (let file of drop360) {
			if (file.type.includes('image/')) {
				allFiles.push(file);
			}
		}

		var uploadFile = () => {
			if (index < allFiles.length) {
				let file = allFiles[index];
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					imagesData.push({
						id: null,
						photoSpheresName: '1',
						imageType: '2',
						photoSpheresImage: reader.result,
						isDeleted: false,
						tempId: lastIndex + 1,
					});
					lastIndex = lastIndex + 1;
					index++;
					uploadFile();
				};
				reader.onerror = (error) => {
					index++;
					uploadFile();
				};
			} else {
				setPhotoSpheres([...imagesData]);
			}
		};
		uploadFile();
		if (allFiles && allFiles.length > 0) {
			let i = 0;
			allFiles.forEach((ele, index) => {
				if (ele) {
					// formData.append('PhotoSpheres[' + i + '].Id', null);
					formData.append('PhotoSpheres[' + i + '].ViewName', '1');
					formData.append('PhotoSpheres[' + i + '].ViewImage', ele);
					formData.append('PhotoSpheres[' + i + '].ImageType', `${'2'}`);
					formData.append('PhotoSpheres[' + i + '].IsDeleted', false);
					i++;
				}
			});
		}
		//updating 360 images here

		// setTimeout(() => {
		// 	uploadData360();
		// }, 500);
		formData.append('WarehouseId', id);
		setIsLoading(true);
		dispatch(postClientWareHouseUpdate360(formData));
		setTimeout(() => {
			setIsLoading(false);
			dispatch(getWareHouseByUserId(props.mainId));
		}, 3000);
	};

	const fileDelete = (e, index) => {
		e.preventDefault();
		if (warehouseImages[index - 1]) {
			warehouseImages[index - 1].isDeleted = true;
		}
		setWarehouseImages([...warehouseImages]);
	};

	const fileDeleteThree = (e, index) => {
		e.preventDefault();
		if (photoSpheres[index - 1]) {
			photoSpheres[index - 1].isDeleted = true;
		}
		setPhotoSpheres([...photoSpheres]);
	};

	const updateWareHouse = (e) => {
		e.preventDefault();
		let formData = new FormData();
		formData.append('id', id);
		formData.append('WarehouseName', warehouseName);
		formData.append('LocationAddress', locationAddress);
		formData.append('City', props.city);
		formData.append('State', props.state);
		formData.append('Country', props.country);
		formData.append('Zipcode', props.zip);
		formData.append('Latitude', props.latitude);
		formData.append('Longitude', props.longitude);
		formData.append('TotalSqaureFootage', parseFloat(totalSqaureFootage));
		formData.append(
			'AvailableSqaureFootage',
			parseFloat(availableSqaureFootage)
		);
		formData.append(
			'StorageInfastructure',
			storage
				.map((ele) => {
					return ele.value;
				})
				.join(',')
		);
		formData.append(
			'FacilityCertifications',
			facilityList
				.map((ele) => {
					return ele.value;
				})
				.join(',')
		);
		formData.append('SecurityComponents', props.securityComponents);
		formData.append('IndustriesServed', industriesServed);
		formData.append('InboundServices', props.inboundServices);
		formData.append('OutboundServices', props.outboundServices);
		formData.append('ValueAddedServices', props.valueAddedServices);
		formData.append('WMSAvailable', props.wmsAvailable);
		formData.append('WMSSupportIntrgration', props.wmsSupportIntrgration);
		formData.append('AlternateWMSOMSOperate', props.alternateWMSOMSOperate);
		formData.append('EcomFulfilmentFimiliar', props.ecomFulfilmentFimiliar);
		if (photoSpheres && photoSpheres.length > 0) {
			photoSpheres.forEach((ele, index) => {
				if (ele.photoSpheresName && ele.photoSpheresImage && ele.imageType) {
					formData.append(
						'PhotoSpheres[' + index + '].ViewName',
						ele.photoSpheresName
					);
					formData.append(
						'PhotoSpheres[' + index + '].ViewImage',
						ele.photoSpheresImage
					);
					formData.append(
						'PhotoSpheres[' + index + '].ImageType',
						ele.imageType
					);
				}
				if (ele.id) {
					formData.append('PhotoSpheres[' + index + '].Id', ele.id);
					formData.append(
						'PhotoSpheres[' + index + '].IsDeleted',
						ele.isDeleted
					);
				}
			});
		}
		if (warehouseImages && warehouseImages.length > 0) {
			let i = 0;
			warehouseImages.forEach((ele, index) => {
				if (ele.id) {
					formData.append('Images[' + i + '].Id', ele.id);
					formData.append(
						'Images[' + i + '].Image',
						ele.image ? ele.image : ele.imageURL
					);
					formData.append('Images[' + i + '].IsDeleted', ele.isDeleted);
					i++;
				} else if (!ele.id && !ele.isDeleted) {
					formData.append(
						'Images[' + i + '].Image',
						ele.image ? ele.image : ele.imageURL
					);
					formData.append('Images[' + i + '].IsDeleted', ele.isDeleted);
					i++;
				}
			});
		}
		setIsLoading(true);
		dispatch(postClientWareHouseUpdate(formData));
		setTimeout(() => {
			setIsLoading(false);
			dispatch(getWareHouseByUserId(props.mainId));
		}, 3000);
	};

	return (
		<>
			<li className="m-2 border border-gray-200 relative bg-white shadow rounded-md px-6 py-4">
				<div>
					<div className="px-4 py-5 sm:px-6">
						<h2
							id="applicant-information-title"
							className="text-lg leading-6 font-medium text-gray-900"
						>
							{warehouseName || ''}
							<span className="px-2 py-2 text-gray-400 bg-gray-200 rounded-lg font-mono text-sm ml-2">PopID {id ? id.split("-")[0] : null}</span>
						</h2>
						<p className="mt-1 max-w-2xl text-sm text-gray-500">
							{`${location} ${locationAddress}` || ''}
						</p>
					</div>
					<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
						<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
							<div className="sm:col-span-1">
								<dt className="text-sm font-medium text-gray-500">
									Total SqFt
								</dt>
								<input
									type="text"
									name="email"
									id="email"
									className="mt-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									placeholder={totalSqaureFootage || ''}
									onChange={(e) => {totalSqaureFootage = e.target.value}}
								/>
							</div>
							<div className="sm:col-span-1 ">
								<dt className=" text-sm font-medium text-gray-500">
									Available SqFt
								</dt>
								<input
									type="text"
									name="email"
									id="email"
									className="mt-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									placeholder={availableSqaureFootage || ''}
									onChange={(e) => {availableSqaureFootage = e.target.value}}
								/>
							</div>
							<div className="sm:col-span-1">
								<dt className="text-sm font-medium text-gray-500">
									Storage Infrastructure
								</dt>
								<MultiSelect
									className="mt-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									options={jsonList.storageInfrastructures}
									value={storage}
									onChange={handleDropdownChange}
									labelledBy={'Storage Infrastructure'}
									overrideStrings={{
										selectSomeItems: 'Storage Infrastructure',
									}}
									disableSearch={true}
									hasSelectAll={false}
								/>
							</div>
							<div className="sm:col-span-1">
								<dt className="text-sm font-medium text-gray-500">
									Facility Certifications
								</dt>
								<MultiSelect
									className="mt-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									options={jsonList.facilityCertifications}
									value={facilityList}
									onChange={handleDropdownChangeFacility}
									labelledBy={'Facility Certifications'}
									overrideStrings={{
										selectSomeItems: 'Facility Certifications',
									}}
									disableSearch={true}
									hasSelectAll={false}
								/>
							</div>

							<div className="sm:col-start-1 sm:col-end-5">
								<dt className="text-sm font-medium text-gray-500">
									{warehouseImages && warehouseImages.length
										? 'Still Photos'
										: ''}
								</dt>
								<dd className="mt-1 text-sm text-gray-900">
									<ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
										{warehouseImages && warehouseImages.length > 0
											? warehouseImages.map((data, i) => {
													return (
														<Fragment key={i}>
															<li
																className={`group pl-3 pr-4 py-3 flex items-center justify-between text-sm ${
																	data.isDeleted ? 'bg-red-100' : ''
																} ${
																	data.id === null && !data.isDeleted
																		? 'bg-green-100'
																		: ''
																}`}
															>
																<div className="w-0 flex-1 flex items-center">
																	<svg
																		className="flex-shrink-0 h-5 w-5 text-gray-400"
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			strokeWidth="{2}"
																			d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
																		/>
																	</svg>
																	<span className="ml-2 flex-1 w-0 truncate">
																		{`${
																			data.id
																				? data.id
																				: Math.random() + 'image'
																		}.jpg`}
																	</span>
																</div>
																<div className="flex flex-row ml-4 flex-shrink-0">
																	<div
																		className="mr-4 flex-shrink-0"
																		onClick={(e) => fileDelete(e, data.tempId)}
																	>
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			fill="none"
																			viewBox="0 0 24 24"
																			stroke="currentColor"
																			className="w-5 text-red-500"
																		>
																			<path
																				strokelinecap="round"
																				strokelinejoin="round"
																				strokewidth="{2}"
																				d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
																			/>
																		</svg>
																	</div>
																	<a
																		onClick={(e) =>
																			openImageLink(e, data.imageURL)
																		}
																		className="font-medium text-blue-600 hover:text-blue-500"
																	>
																		View
																	</a>
																</div>
															</li>
														</Fragment>
													);
											  })
											: null}
									</ul>
									<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
										<div className="mt-1 sm:mt-0 sm:col-span-3">
											{/* <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div> */}
											<div
												onClick={(e) => e.stopPropagation()}
												{...getRootProps()}
												className={` ${
													isDragActive
														? `border-gray-400 bg-gray-300`
														: `border-gray-300`
												} w-full flex justify-center px-6 pt-5 pb-6 border-2  border-dashed rounded-md`}
											>
												<div className="space-y-1 text-center">
													<svg
														className="mx-auto h-12 w-12 text-gray-400"
														stroke="currentColor"
														fill="none"
														viewBox="0 0 48 48"
														aria-hidden="true"
													>
														<path
															d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
															strokeWidth={2}
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
													<div
														className="flex text-sm text-gray-600"
														onClick={(e) => e.stopPropagation()}
													>
														<label
															// htmlFor="file-upload-image"
															className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
														>
															<span>Upload Still Photos</span>
															{/* <input
																id="file-upload"
																name="file-upload"
																type="file"
																className="sr-only"
															/> */}
															<input
																// type="file"
																// accept="image/*"
																// multiple={true}
																// className="sr-only"
																// name="warehouseImages"
																// onChange={(event) =>
																// 	handleMultipleFileUplod(event)
																// }

																{...getInputProps()}
															/>
														</label>

														<p className="pl-1">or drag and drop here</p>
													</div>
													<p className="text-xs text-gray-500">
														PNG, JPG, GIF up to 10MB per Image
													</p>
												</div>
											</div>
										</div>
									</div>
								</dd>
							</div>
							<div className="sm:col-start-1 sm:col-end-5">
								<dt className="text-sm font-medium text-gray-500">
									{photoSpheres && photoSpheres.length
										? `360${'\u00b0'} Photos`
										: ''}
								</dt>
								<dd className="mt-1 text-sm text-gray-900">
									<ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
										{photoSpheres && photoSpheres.length
											? photoSpheres.map((data, i) => {
													return (
														<li
															className={`group pl-3 pr-4 py-3 flex items-center justify-between text-sm ${
																data.isDeleted ? 'bg-red-100' : ''
															} ${
																data.id === null && !data.isDeleted
																	? 'bg-green-100'
																	: ''
															}`}
														>
															<div className="w-0 flex-1 flex items-center">
																<svg
																	className="flex-shrink-0 h-5 w-5 text-gray-400"
																	xmlns="http://www.w3.org/2000/svg"
																	fill="none"
																	viewBox="0 0 24 24"
																	stroke="currentColor"
																>
																	<path
																		strokelinecap="round"
																		strokelinejoin="round"
																		strokewidth="{2}"
																		d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
																	/>
																</svg>
																<span className="ml-2 flex-1 w-0 truncate">
																	{`${
																		data.id ? data.id : Math.random() + 'image'
																	}.jpg`}
																</span>
															</div>
															<div className="flex flex-row ml-4 flex-shrink-0">
																<div
																	className="mr-4 flex-shrink-0"
																	onClick={(e) =>
																		fileDeleteThree(e, data.tempId)
																	}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																		className="w-5 text-red-500"
																	>
																		<path
																			strokelinecap="round"
																			strokelinejoin="round"
																			strokewidth="{2}"
																			d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
																		/>
																	</svg>
																</div>
																<a
																	onClick={(e) =>
																		openImage3DLink(e, data.photoSpheresImage)
																	}
																	className="font-medium text-blue-600 hover:text-blue-500"
																>
																	View
																</a>
															</div>
														</li>
													);
											  })
											: null}
									</ul>
									<ThreeUpload
										handleMultipleFileUplodThree={handleMultipleFileUplodThree}
									/>
								</dd>
							</div>
						</dl>
					</div>
					<div>
						<a
							onClick={updateWareHouse}
							className="block bg-indigo-500 text-sm font-medium text-white text-center px-4 py-4 hover:bg-indigo-700 sm:rounded-lg flex justify-center"
						>
							{isLoading ? <Spinner /> : 'Update Warehouse'}
						</a>
					</div>
				</div>
			</li>
			<ImagePreview
				image={wareHouseImage}
				isPreview={modalOpen}
				disableLink={disableLink}
			/>
			<Image3DPreview
				image={wareHouse3DImage}
				isPreview={modal3DOpen}
				disableLink={disable3DLink}
			/>
		</>
	);
}
