import { GET_CLIENTS_SHIPPERS, GET_CLIENTS_SHIPPERS_FAILED, GET_CLIENTS_SUPPLIERS, GET_CLIENTS_SUPPLIERS_FAILED } from '../actions/types'

const initialState = []

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CLIENTS_SUPPLIERS:
            return {
                ...state,
                Suppliers: payload,
            };
        case GET_CLIENTS_SHIPPERS:
            return {
                ...state,
                Shippers: payload,
            };
        default:
            return {
                ...state
            }
    }
} 