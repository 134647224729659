import jwtDecode from 'jwt-decode';


export function isTokenAlive(tokenDecoded) {
    const now = Date.now().valueOf() / 1000;
    if (typeof tokenDecoded.exp === 'undefined' || tokenDecoded.exp < now) {
        return false;
    }
    return true;
}

export function GetTokenDetails(token) {
    if (token) {
        return jwtDecode(token);
    }
    return null;
}

export function isUserAuthenticated(token) {
    const user = GetTokenDetails(token);
    if (user) {
        return isTokenAlive(user);
    } else {
        return false;
    }
}
/**
 * this function tells the user has logged in or not
 */
export function checkLoginStatus() {
    const token = localStorage.getItem('token');
    if (token) {
        const isAuth = isUserAuthenticated(token);
        return isAuth;
    } else {
        return false;
    }
}