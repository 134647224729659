import ReactPaginate from 'react-paginate';
export default function Paginator({
	onPageChange,
	selectedPage,
	totalCount,
	limit,
}) {
	const pageCount = (total, perCount) => {
		const count = Math.ceil(total / perCount);
		return count;
	};
	return (
		<ReactPaginate
			containerClassName="flex-none border-t border-gray-200 flex items-center justify-between px-4 pb-8"
			pageCount={pageCount(totalCount, limit)}
			previousLabel={'Previous'}
			nextLabel={'Next'}
			pageLinkClassName="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
			nextClassName="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
			previousClassName="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
			activeClassName="text-indigo-600"
			activeLinkClassName="text-indigo-600"
			onPageChange={onPageChange}
			initialPage={0}
			marginPagesDisplayed={5}
			forcePage={selectedPage}
		/>
	);
}
