import ClientsService from '../services/clientServices'
import { POST_CLIENT_UPDATE, POST_CLIENT_UPDATE_FAILED } from './types'

export const postClientUpdate = (formObj) => (dispatch) => {
    const token = 'Bearer ' + localStorage.getItem('token')
    return ClientsService.postClientUpdate(formObj, token).then(
        (response) => {
            dispatch({
                type: POST_CLIENT_UPDATE,
                payload: response.data.value
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: POST_CLIENT_UPDATE_FAILED
            });
            return Promise.reject();
        }
    );
};