import {
  POST_LOGIN,
  POST_LOGIN_FAILED,
  LOGOUT
} from "../actions/types"

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case POST_LOGIN:
      return {
        ...state,
        user_details: payload,
      };
    case POST_LOGIN_FAILED:
      return {
        ...state,
        user_details: payload,
      };
    case LOGOUT:
      return {
        state: initialState
      }
    default:
      return state;
  }
}