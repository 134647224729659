import axios from "axios";
import { baseURL } from "../constants";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";

/**
 * Fetching proposals
 *
 */
const fetchProposals = (token) => {
  return axios.get(baseURL + "api/Admin/proposals?from=0&to=427", {
    headers: { Authorization: token },
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { fetchProposals };
